import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@components/Layout'
import Link from 'gatsby-link'
import { Seo } from '@components/Seo'
import PageTitle from '@components/PageTitle'
import { Article, TitleParagraph } from '@global'

const ChurchIndex = ({ data }) => (
  <Layout>
    <Seo title="Churches | The Town and Business Guide For Residents and Visitors" />
    <PageTitle title="Churches" />
    <TitleParagraph>Below is a list of Churches In Sowerby Bridge</TitleParagraph>
    <Article>
      {data.allDatoCmsCommunityLink.edges.map(({ node }, index) => (
        <div key={index} className="post">
          <h1 className="post-title">
            <Link to={`/community/${node.slug}`}>
              <h2>{node.title}</h2>
            </Link>
          </h1>
        </div>
      ))}
    </Article>
  </Layout>
)

export const Churchgraph = graphql`
  {
    allDatoCmsCommunityLink(filter: { organisation: { title: { eq: "Church" } } }) {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`

export default ChurchIndex
